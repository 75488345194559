@font-face {
  font-family: 'almoni-neue-regular';
  src: url("./assets/fonts/almoni-regular-aaa.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'almoni-neue-bold';
  src: url("./assets/fonts/almoni-bold-aaa.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

:root {
  --main-color: #D93C3C;
  --main-color-disabled: #ce78ae;
  --main-color-mid: #f180a4;
  --text-color-regular: #787878;
  --input-background: #FBFBFB;
  --title-text-color: #676767;
  --text-disabled: #bfbfbf;
  --app-background-color: #efefef;
  --app-disabled-btn-bg: #d0d0d0;
  --app-background-light-color: #fff;
  --app-background-light-bg: #f9f9f9;
  --app-background-disabled: #e1e1e1;
  --header-footer-background: #fff;
  --button-disabled: #cecece;
  --button-disabled-text: #989898;

  --xs: 0;
  --sm: 576px;
  --med: 768px;
  --lg: 992px;
  --xl: 1200px;
  --xxl: 1400px;

  --font-s: 14px;
  --font-m: 16px;
  --font-l: 17px;
  --font-xl: 20px;
  --font-xxl: 24px;
  --font-xxxl: 30px;
  --font-f-title: 20px;
  --font-22: 22px;

  --f-reg: 'almoni-neue-regular';
  --f-bold: 'almoni-neue-bold';
}

html{
  direction: rtl;
}

input:not([type="checkbox"]), select, textarea, .MuiOutlinedInput-root{
  background-color: var(--input-background) !important;
}

.disabled_btn{
  background-color: var(--button-disabled) !important;
}

a, a:hover{
  color: var(--main-color);
}

.font-xxl{
  font-size: var(--font-xxl) !important;
}

.font-s{
  font-size: var(--font-s) !important;
}

.font-m{
  font-size: var(--font-m) !important;
}

.font-l{
  font-size: var(--font-l) !important;
}

.font-xl{
  font-size: var(--font-xl) !important;
}

h1, .font-xxxl{
  font-size: var(--font-xxxl) !important;
}

.font-22{
  font-size: var(--font-22);
}

.font-f-title{
  font-size: var(--font-f-title);
}

.f-reg{
  font-family: var(--f-reg);
}

.f-bold{
  font-family: var(--f-bold);
}

.text-color-regular{
  color: var(--text-color-regular) !important;
}

.text-disabled{
  color: var(--text-disabled);
}

.main-color-bg{
  background-color: var(--main-color);
}

.disabled-btn-bg{
  background-color: var(--app-disabled-btn-bg);
}

body{
  background-color: var(--app-background-color);
  color: var(--text-color-regular);
}

.App{
  min-height: 100%;
}

.btn-primary{
  background-color: var(--header-footer-background) !important;
  color: var(--main-color) !important;
}

.btn-primary:hover{
  background-color: var(--main-color) !important;
  color: var(--app-background-light-color) !important;
}

.btn-primary{
  width: 124px !important;
}

.btn-primary:disabled{
  background-color: var(--button-disabled) !important;
  color: var(--button-disabled-text) !important;
}

.btn{
  border-radius: 0;
  border: none;
  outline: none;
}

.btn span{
  font-size: 13px;
}

.pointer{
  cursor: pointer;
}

.text-color-selected{
  color: var(--title-text-color);
}

.primary_color{
  color: var(--main-color) !important;
}

.btn:focus,
.form-control:focus{
  box-shadow: none;
}

.btn.btn-primary:focus{
  background-color: var(--main-color);
}

/* .MuiDataGrid-cellContent,
.MuiDataGrid-columnHeaderTitle{
  font-family: var(--f-reg);
}

.MuiDataGrid-cell:not(:last-of-type){
  display: flex;
  align-items: flex-start !important;
}

.MuiDataGrid-row .MuiDataGrid-cell:first-child{
  align-items: center !important;
}

.MuiDataGrid-cell:focus{
  outline: none !important;
} */

.modal-content{
  border-radius: 0;
}

select:focus{
  box-shadow: none !important;
}

.min-height-100{
  min-height: calc(100vh - 126px);
}

.MuiFormHelperText-root{
    text-align: right !important;
    margin-right: 0 !important;
    font-family: var(--f-reg) !important;
    font-size: var(--font-s) !important;
}

.popup_btn{
    background-color: var(--main-color) !important;
    color: var(--app-background-light-color) !important;
}

label{
  color: var(--title-text-color);
}

.min-100{
  min-height: 98px;
}

.form-check-input[type=checkbox]{
  border-radius: 0;
}

.pre-wrap{
  white-space: pre-wrap;
}



@media screen and (max-width: 768px){

  .font-xxl{
    font-size: 19px !important;
  }

  .font-xl{
    font-size: 18px !important;
  }

  h1{
    font-size: 26px !important;
  }
  .f-reg{
    font-size: 15px;
  }

}
.sr-only{
  position: absolute;
  font-size: 0;
  line-height: 0;
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
/* high contrast */
.contrast-toggler-wrapper{
  position: absolute;
  top: 20px;
  left: 20px;
}
.contrast-toggler {
  font-size: 0;
  width: 17px;
  height: 17px;
  letter-spacing: 0;
  display: flex;
  border: 1px solid #D93C3C;
  border-radius: 50%;
  overflow: hidden;
}
.contrast-toggler::before,
.contrast-toggler::after{
  content: '';
  width: 50%;
  display: block;
  height: 100%;
}
.contrast-toggler::before{
  background-color: transparent;
}
.contrast-toggler::after{
  background-color: #D93C3C;
}
.high-contrast .contrast-toggler,
.high-contrast .header_logo img{
  filter: brightness(50%);
}
.high-contrast input::placeholder{
  color: #000 !important
}
.high-contrast textarea::placeholder {
  color: #000 !important
}

body.high-contrast{
  background: #fff;
}
.high-contrast .accordion-button:not(.collapsed),
.high-contrast .MuiStepLabel-iconContainer.Mui-disabled,
.high-contrast .MuiStepLabel-iconContainer .Mui-active,
.high-contrast .MuiStepLabel-iconContainer .Mui-completed,
.high-contrast .MuiStepLabel-label,
.high-contrast .MuiStepLabel-label.Mui-active,
.high-contrast,
.high-contrast h1,
.high-contrast h2,
.high-contrast label,
.high-contrast .text-disabled{
  color: #000 !important;
}
.high-contrast .MuiStepConnector-root span,
.high-contrast .MuiStepLabel-iconContainer.Mui-disabled {
  border-color: #000;
}
.high-contrast select,
.high-contrast .input-group input,
.high-contrast .input-group textarea,
.high-contrast .input-group .form-select,
.high-contrast .input-group select
.high-contrast input[type=text],
.high-contrast input[type=tel],
.high-contrast input[type=email],
.high-contrast input[type=password],
.high-contrast input[type=url],
.high-contrast textarea{
  background-color: #fff !important;
  border: 1px solid #000 !important;
  color: #000;
}
.high-contrast .MuiOutlinedInput-root {
  background: #fff !important;
}
.high-contrast .MuiOutlinedInput-root [class*='MuiOutlinedInput-notchedOutline']{
  border-color: #000;
}
.high-contrast .MuiOutlinedInput-root input{
  border-color: transparent !important;
}
.high-contrast .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
}
.high-contrast .close_circle_icon svg path,
.high-contrast .css-ptiqhd-MuiSvgIcon-root,
.high-contrast .MuiStepLabel-iconContainer.Mui-disabled .MuiStepIcon-text,
.high-contrast .f-bold svg path:first-child{fill: #000;}
.high-contrast .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track,
.high-contrast .btn-primary{
  background: #000 !important;
  color: #fff !important;
}
.high-contrast .text-color-regular,
.high-contrast .MuiSwitch-switchBase:after,
.high-contrast .muirtl-1cevji9-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked:after{
  color: #000 !important;
}
.high-contrast .muirtl-1yjjitx-MuiSwitch-track{
  opacity: 1;
}
.high-contrast .form-check-input:checked {
  background-color: #000;
  border-color: #000;
}
.high-contrast .btn-primary:disabled{
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  opacity: 1;
}
.note{
  display: block;
}
.accordion .note{
  display: none;
}
.accordion fieldset:first-child .note{
  display: block;
}