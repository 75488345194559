.MuiStep-root .MuiStepConnector-root{
    left: calc(50% + 20px);
    right: calc(-50% + 20px);
}

.MuiStepLabel-iconContainer.Mui-disabled{
    border: 1px solid var(--main-color);
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
}

.MuiStepLabel-iconContainer.Mui-disabled svg{
    color: transparent !important;
    transition: all 0.2s ease-in-out;
}

.MuiStepLabel-iconContainer.Mui-disabled .MuiStepIcon-text{
    fill: var(--main-color);
    transition: all 0.2s ease-in-out;
}

.MuiStepLabel-iconContainer .Mui-active,
.MuiStepLabel-iconContainer .Mui-completed{
    color: var(--main-color) !important;
    transition: all 0.2s ease-in-out;
}

.MuiStepConnector-root span{
    border-color: var(--main-color);
    transition: all 0.2s ease-in-out;
}

.MuiStepLabel-label{
    color: var(--main-color);
    font-family: 'almoni-neue-regular' !important;
}

.MuiStepLabel-label.Mui-active{
    color: var(--main-color) !important;
    font-weight: bold !important;
    font-family: 'almoni-neue-bold';
} 

.ActionForm .stepper_con{
    width: 585px;
}

.MuiSwitch-root.MuiSwitch-sizeMedium{
    margin-left: -7px;
}

.input-group input,
.input-group textarea,
.input-group .form-select,
.input-group select{
    resize: none;
    color: var(--title-text-color);
    border-radius: 0 !important;
}

.input-group input:disabled
.input-group textarea:disabled{
    background-color: #e1e1e1;
}

.partnerBlock input:disabled,
.budgetBlock input:disabled,
.partnerBlock textarea:disabled,
.partnerBlock select:disabled{
    background-color: #f0f0f0;
}



.was-validated textarea.form-control:valid, 
textarea.form-control.is-valid,
.was-validated .form-select:invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple]):not([size]) {
    padding-right: 10px;
}

.disabled_back{
    background-color: var(--app-background-disabled);
}

.close_circle_icon.gray_block{
    top: 5px;
    left: 9px;
}

.close_circle_icon.gray_block svg{
    width: 15px;
}

.close_circle_icon.gray_block:hover svg path{
    fill: var(--main-color);
}

.disabled_back .close_circle_icon.gray_block:hover svg path{
    fill: #D8D8D8;
}

.is-invalid.form-control:valid,
.was-validated #FieldId:valid{
    background-image: none;
}

.MuiLinearProgress-bar{
    background-color: var(--main-color) !important;
}

.alert-primary{
    background-color: #b6007230;
}

.ActivitySection_form,
.ContactSection_form{
    max-width: 690px;
}

.accordion-button::after{
    margin-left: 0;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-header{
    border-color: var(--title-text-color) !important;
}

.accordion-button:not(.collapsed) {
    color: var(--title-text-color);
    background-color: var(--app-background-light-color); 
    box-shadow: none;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button{
    font-weight: bold;
    color: var(--title-text-color);
}

.MuiChip-root.MuiChip-filled{
    height: 23px;
}

.MuiList-root li .MuiSvgIcon-root{
    font-size: 0.95rem;
} 

.MuiList-root li .MuiListItemText-primary{
    /* height: 40px; */
    font-family: var(--f-reg) !important;
}

.MuiChip-label,
input.MuiInputBase-input,
.MuiTypography-root{
    font-family: var(--f-reg) !important;
}

.MuiAutocomplete-root .MuiOutlinedInput-root{
    padding-top: 1.5px !important;
    padding-bottom: 1.5px !important;
}

.accordion_checkbox{
    margin-right: 170px;
    top: 7px;
    z-index: 99;
}

.accordion_switch .MuiSwitch-root{
    direction: ltr;
}

.close_circle_icon{
    top: 19px;
    left: 19px;
    padding: 0;
    border: none;
    background: none;
}

.MuiAutocomplete-root .MuiInputBase-root{
    border-radius: 0 !important;
}

.tooltip_line{
    width: 246px;
    height: auto;
    z-index: 99;
    color: var(--title-text-color);
    left: 0;
    top: 24px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
}

.MuiCheckbox-root{
    margin-right: -12px !important;
}

.Mui-disabled.MuiOutlinedInput-root{
    background-color: #e9ecef;
}

.accordion-body {
    padding: 0 1.25rem 1rem 1.25rem;
}

.col-time{
    width: 43%;
    display: inline-block;
    margin-left: 14% !important;
}

.col-time ~ .col-time{
    margin-left: 0% !important;
}

.form-control.is-invalid, 
.was-validated .form-control:invalid,
.was-validated #FieldId:invalid,
.form-select.is-invalid:not([multiple]):not([size]){
    background-image: none;
}

.form-select{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='darkgray' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")
}

.thankyou_msg .btn-primary{
    width: 200px !important;
}
.note{
    margin: 0 0 20px;
}
@media screen and (max-width: 768px){
    .ActionForm .stepper_con{
        width: 100%;
    }
    .col-time{
        width: 46%;
        display: inline-block;
        margin-left: 8% !important;
    }
    .col-time ~ .col-time{
        margin-left: 0% !important;
    }
}