.main_con_404{
    margin-top: -55px;
    margin-bottom: -55px;
    height: 87vh;
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
    background-image: url('https://s3-eu-west-1.amazonaws.com/good-deeds-day.org.il-uploads/wp-content/uploads/2018/02/404_bg_desktop.jpg')
}

.main_con_404 h1{
    font-size: 180px !important;
    color: #333;
    font-weight: bold;
}

.main_con_404 p{
    font-size: 35px;
    color: #333;
}