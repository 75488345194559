.HomePage .select_con,
.ActivitySection_form .select_con{
    max-width: 388px
}

.HomePage .section_container,
.HomePage form .section_container{
    max-width: 500px;
    margin: 0 auto;
}

.HomePage form .section_container p{
    max-width: 281px;
}


@media screen and (max-width: 768px){

    .notice_block{
        display: flex;
        flex-direction: column;
    }
}