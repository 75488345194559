.pop_title{
    max-width: 530px;
}

@media screen and (max-width: 768px){
    .gray_section_popup{
        display: flex;
        flex-direction: column;
    }
    .notic_popup h1{
        font-size: 19px !important;
    }
}