.Footer{
  height: 70px;
  background-color: var(--header-footer-background);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Footer span{
  color: #ccc;
}

.Footer .links_con a{
  color: var(--text-color-regular);
  margin: 0 9px;
  text-decoration: none;
}

.arison_icon svg{
  width: 85px
}


@media screen and (max-width: 768px){
  .Footer .links_con{
    margin-bottom: 2px;
  }

  .Footer{
    flex-direction: column;
    height: 80px;
  }

  .arison_icon svg{
    width: 75px
  }
}
