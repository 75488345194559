.header_comp{
  
  background-color: var(--header-footer-background);
}

.header_comp .header_logo{
  position: absolute;
  top: 0;
  right: 23px;
}

nav.navbar {
  min-height: 56px;
}

@media screen and (max-width: 768px){
  .header_comp .header_logo {
    right: -2px;
  }
}